require('dotenv').config();
const express = require('express');
const path = require('path');
const app = express();
const port = process.env.PORT || 3000;

// Initialize Firebase Admin SDK
const admin = require('firebase-admin');
const serviceAccount = JSON.parse(Buffer.from(process.env.FIREBASE_SERVICE_ACCOUNT_KEY, 'base64').toString('ascii'));

admin.initializeApp({
  credential: admin.credential.cert(serviceAccount),
});

// Middleware and Routes
// Adjust the path for the middleware and routes since index.js is inside the src folder
const auth = require(path.join(__dirname, 'middleware', 'auth'));
const authRoutes = require(path.join(__dirname, 'routes', 'auth-routes'));

// Initialize auth middleware
auth(app);

// Serve static files from the 'public' directory in development and 'build' directory in production
if (process.env.NODE_ENV === 'production') {
  // In production, serve static files from the 'build' directory
  app.use(express.static(path.join(__dirname, '..', 'build')));
  app.get('*', (req, res) => {
    res.sendFile(path.join(__dirname, '..', 'build', 'index.html'));
  });
} else {
  // In development, serve static files from the 'public' directory
  app.use(express.static(path.join(__dirname, '..', 'public')));
  app.get('/', (req, res) => {
    res.sendFile(path.join(__dirname, '..', 'public', 'index.html'));
  });
}

// Auth routes
app.use('/auth', authRoutes);

app.listen(port, () => {
  console.log(`Server running on http://localhost:${port}`);
});

module.exports = app;
